.ReportFormContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 20px;
}

.ReportFormContainer .LeftForm,
.ReportFormContainer .RightForm {
  width: 100% !important;
}

.ReportSmallFormWrapper .aboutLabel {
  margin: 0 !important;
}

.ReportSmallFormWrapper .area_text {
  margin: 10px 0 0 0 !important;
  width: 100% !important;
}

@media (max-width: 1124px) {
  .ReportFormContainer {
    flex-direction: column;
    align-items: initial;
  }
}