.Cont {
  width: 100%;
}

.detailsHeaderBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.detailsHeaderBoxInside {
  /* width: 100%; */
  display: flex;
  align-items: center;
}

.greyBox {
  width: 100%;
  border-radius: 10px;
  padding: 30px;
  background-color: #f0f1f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.greyBoxVer {
  width: 100%;
  border-radius: 10px;
  padding: 30px;
  background-color: #f0f1f3;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 30px;
  flex-wrap: wrap;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
}

.greyBox:hover,
.greyBoxVer:hover {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.15);
}

.greyBoxWhite {
  margin-bottom: 20px;
  background-color: #f0f1f3;
  border-radius: 10px;
  padding: 20px;
}

.greyBoxWhite:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.greyNumberBox {
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

.SchoolLogo {
  height: 200px;
  margin: auto;
}

.SchoolSocialIcon {
  font-size: 40px !important;
  margin: 15px 10px 0 0;
  cursor: pointer;
}

.EditDeleteHeader {
  display: flex;
  justify-content: flex-end;
  margin: 0 15px;
}

@media (max-width: 1124px) {
  .Cont {
    flex-direction: column;
  }

  .EditDeleteHeader {
    justify-content: space-evenly;
  }

  .SchoolLogo {
    width: 180px;
  }

  .SchoolSocialIcon {
    font-size: 20px !important;
  }

  .greyBox {
    padding: 10px;
  }

  .greyBoxBlock {
    padding: 10px;
    height: 400px;
  }

  .chartsCont {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .greyBoxWhite {
    padding: 10px;
  }

  .detailsHeaderBoxInside {
    flex-direction: column;
    align-items: center;
  }

  .SchoolLogo {
    margin: 10px 0 0 0;
  }

  .greyNumberBox {
    padding: 10px;
    margin: 0;
  }

  .SchoolSocialIcon {
    font-size: 30px !important;
    margin: 0;
  }
}
