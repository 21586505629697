.labelPhone {
  position: absolute;
  top: 4px;
  z-index: 1;
  left: 70px;
  font-size: 12px;
  background: white;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}
